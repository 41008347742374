import Vue from 'vue'
import Vuex from 'vuex'

Vue.use(Vuex)

export default function createStore() {
	return new Vuex.Store({
		state: {
			host: '',
			// deviceType: 'pc',
			deviceType: 'mobile',
			showDebug: false,
			adsensConfig: {
				scriptUrl: 'https://pagead2.googlesyndication.com/pagead/js/adsbygoogle.js?client=ca-pub-8381337407740666',
				home_1: { class: 'adsbygoogle', style: 'display:block', 'data-ad-client': 'ca-pub-8381337407740666', 'data-ad-slot': '4914948235', 'data-ad-format': 'auto', 'data-full-width-responsive': 'true' },
				home_masonry: [
					{ class: 'adsbygoogle', style: 'display:block', 'data-ad-client': 'ca-pub-8381337407740666', 'data-ad-slot': '5691837697', 'data-ad-format': 'auto', 'data-full-width-responsive': 'true' },
					{ class: 'adsbygoogle', style: 'display:block', 'data-ad-client': 'ca-pub-8381337407740666', 'data-ad-slot': '4595384035', 'data-ad-format': 'auto', 'data-full-width-responsive': 'true' },
					{ class: 'adsbygoogle', style: 'display:block', 'data-ad-client': 'ca-pub-8381337407740666', 'data-ad-slot': '3350918262', 'data-ad-format': 'auto', 'data-full-width-responsive': 'true' },
					{ class: 'adsbygoogle', style: 'display:block', 'data-ad-client': 'ca-pub-8381337407740666', 'data-ad-slot': '2874102662', 'data-ad-format': 'auto', 'data-full-width-responsive': 'true' },
				],

				detail_1: { class: 'adsbygoogle', style: 'display:block', 'data-ad-client': 'ca-pub-8381337407740666', 'data-ad-slot': '1561020995', 'data-ad-format': 'auto', 'data-full-width-responsive': 'true' },
				detail_masonry: [
					{ class: 'adsbygoogle', style: 'display:block', 'data-ad-client': 'ca-pub-8381337407740666', 'data-ad-slot': '7934857651', 'data-ad-format': 'auto', 'data-full-width-responsive': 'true' },
					{ class: 'adsbygoogle', style: 'display:block', 'data-ad-client': 'ca-pub-8381337407740666', 'data-ad-slot': '8411673256', 'data-ad-format': 'auto', 'data-full-width-responsive': 'true' },
					{ class: 'adsbygoogle', style: 'display:block', 'data-ad-client': 'ca-pub-8381337407740666', 'data-ad-slot': '7098591583', 'data-ad-format': 'auto', 'data-full-width-responsive': 'true' },
					{ class: 'adsbygoogle', style: 'display:block', 'data-ad-client': 'ca-pub-8381337407740666', 'data-ad-slot': '3071082203', 'data-ad-format': 'auto', 'data-full-width-responsive': 'true' },
				],
			},
			adPosConfig: {
				home: [3, 5, 8, 11],
				detail: [3, 5, 8, 11],
			},
		},
		mutations: {
			setHost(state, host) {
				state.host = host
			},
			setTitle(state, title) {
				state.title = title
			},
			setDomain(state, domainConfig) {
				state.domainConfig = domainConfig
			},
			setDeviceType(state, deviceType) {
				state.deviceType = deviceType
			},
			setDebug(state, showDebug) {
				state.showDebug = showDebug
			},
		},
		getters: {
			showDebug: (state) => state.showDebug,
			deviceType: (state) => state.deviceType,
			host: (state) => state.host,
			adsensConfig: (state) => state.adsensConfig,
			adPosConfig: (state) => state.adPosConfig,
		},
	})
}
